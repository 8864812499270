import React from "react"
import { Card, Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import ScoreDescription from "../ScoreDescription/ScoreDescription"
import CardHeader from "../CardHeader/CardHeader"
import ScoreGauge from "../ScoreGauge/ScoreGauge"

export interface MiniCardProps {
  ref?: React.Ref<any>
  className?: string
  onClick?: (e: MouseEvent) => void
  scoreCategory: any
  scoreSeverity: any
  scoreData: any
  averages: any
  address: any
}

const MiniCard: React.FC<MiniCardProps> = ({
  scoreCategory = null,
  scoreSeverity = null,
  scoreData = null,
  averages = null,
  address,
}) => {
  let capsHeadline = scoreCategory;
  if (scoreCategory === "Storm") {
    capsHeadline = "Precip";
  }
  return (
    <div>
      <div className="cardWrapper">
        <Card className="gaugeCard miniCard">
          <div className="cardTop">
            <CardHeader
              capsHeadline={`${capsHeadline} Risk`}
              largeHeadline={scoreSeverity ? scoreSeverity.label : ""}
            />
            <div className="gaugeWrapper">
              <ScoreGauge
                capsHeadline={capsHeadline}
                score={scoreData.score}
                autoGradient={true}
                defaultProps={{
                  strokeWidth: "8px",
                }}
              />
            </div>
          </div>
          <div className="iconContain">
            <Icon icon={IconNames.MAXIMIZE} color="#e9e9e9" />
          </div>
          <div className="cardBottom">
            {averages !== null ? (
              <ScoreDescription
                address={address}
                scoreCategory={scoreCategory}
                scoreSeverity={scoreSeverity}
                scoreData={scoreData}
                averages={averages}
              />
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default MiniCard
